<template>
    <div>
        <div v-show="this.ruleDefinitionID == 0">
            <h3>Surcharge Definitions</h3>
            <table class="table table-striped">
                <tbody>
                <tr @click="getDefinitionRules(definitionID)" v-for="[definitionID, definition] in ruleDefinitions" :key="definitionID">
                    <td>{{ definition }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-show="ruleDefinitionID > 0 && !ruleGroup">
            <h3>Rule Groups</h3>
            <span class="glyphicon glyphicon-arrow-left" @click="ruleDefinitionID = 0"></span>
            <div class="form-group">
                <label>Search</label>
                <input type="text" class="form-control" v-model="ruleGroupSearchTerm">
            </div>
            <table class="table table-striped pointer">
                <tbody>
                <tr @click="getRuleConditions(ruleGroupID)" v-for="[ruleGroupID, ruleGroupName] in filteredRuleGroups" :key="ruleGroupID">
                    <td>{{ ruleGroupName }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-show="ruleDefinitionID > 0 && ruleGroup">
            <h3>Surcharge Rule Group: {{ ruleGroup }}</h3>
            <h4>If these conditions are true:</h4>
            <span class="glyphicon glyphicon-arrow-left" @click="ruleGroup = ''"></span>
            <table class="table">
                <thead>
                <tr>
                    <th>Field</th>
                    <th>Operator</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="condition in this.requiredConditions" :key="condition.surcharge_rule_id">
                    <td>{{ condition.quote_field }}</td>
                    <td>{{ condition.operator }}</td>
                    <td>{{ modifyData(condition) }}</td>
                </tr>
                </tbody>
            </table>

            <h4>Proceed to these rules and apply the first applicable rule:</h4>
            <table class="table">
                <thead>
                <tr>
                    <th>Field</th>
                    <th>Operator</th>
                    <th>Value</th>
                    <th class="number">Surcharge</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="condition in this.nonRequiredConditions" :key="condition.surcharge_rule_id">
                    <td>{{ condition.quote_field }}</td>
                    <td>{{ condition.operator }}</td>
                    <td>{{ modifyData(condition) }}</td>
                    <td class="number">${{ condition.price.toLocaleString()}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>

import { store } from '@/store/Tool.store';

export default {
    data() {
        return {
            ruleDefinitionID: 0,
            ruleGroup: '',
            ruleGroups: [[0, 'Options not available']],
            ruleDefinitions: [[0, 'Options not available']],
            ruleGroupSearchTerm: '',
            ruleData: [],
            cache: {
                cdiLocation: [
                    [1, 'Minneapolis'],
                    [2, 'Seattle'],
                    [3, 'Charlotte'],
                    [4, 'Dallas'],
                    [5, 'Phoenix'],
                ]
            }
        }
    },
    created() {
        this.getSurchargeDefinitions();
    },
    methods: {
        getSurchargeDefinitions: function() {
            store.api('/menu_builder/surcharge_definitions', {}).then((res) => this.ruleDefinitions = res);
        },
        getDefinitionRules: function(definitionID) {
            this.ruleDefinitionID = definitionID;
            store.api('/menu_builder/surcharge_rule_groups', {'params[surcharge_definition_id]': definitionID}).then(res => this.ruleGroups = res);
        },
        getRuleConditions: function(ruleGroup) {
            this.ruleGroup = ruleGroup;
            store.api('/surcharge/get_surcharge_rules', { 'params[rule_group]': ruleGroup }).then(res => this.ruleData = res.data);
        },
        modifyData: function(line) {
            if (line.quote_field == 'cdi_location_id') {
                let index = parseInt(line.compare_to) - 1
                return this.cache.cdiLocation[index][1]
            } else {
                return line.compare_to
            }
        }
    },
    watch: {
        ruleDefinitionID: function() {
            if (this.ruleDefinitionID > 0) {
                this.getDefinitionRules(this.ruleDefinitionID);
            }
        }
    },
    computed: {
        filteredRuleGroups: function() {
            if (this.ruleGroupSearchTerm) {
                return this.ruleGroups.filter(item =>
                    item.some(value => value.includes(this.ruleGroupSearchTerm))
                );
            } else {
                return this.ruleGroups;
            }
        },
        requiredConditions: function() {
            if (this.ruleData.length) {
                return this.ruleData.filter(item => item && item.required == 1);
            } else {
                return [];
            }
        },
        nonRequiredConditions: function() {
            if (this.ruleData.length) {
                return this.ruleData.filter(item => item && item.required == 0);
            } else {
                return [];
            }
        }
    }
}

</script>